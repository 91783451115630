import { getProfileSelector } from '@utils/selectors';
import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import constant from '@utils/constant';

const ProfileLayout = memo(() => {
   const { data: profileData, loading: profileLoading } = useSelector(getProfileSelector);

   useEffect(() => {
      if (!profileLoading) {
         if (profileData) {
            navigate(constant.ROUTE_PROFILE_INFO, { replace: true });
         } else {
            navigate(constant.ROUTE_HOME);
         }
      }
   }, [profileLoading, profileData]);
   return null;
});

export default ProfileLayout;
